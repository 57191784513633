.fontitalic {
  font-style: italic;
}

.mainContent {
  padding-top: 100px;
  background: #000;
  min-width: 1366px;
  overflow-x: auto;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  text-decoration: none;
}

input {
  outline: none;
}

li {
  list-style-type: none;
}

.footer {
  margin-top: 2.11rem;
}
