.headerCom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: #000;
  padding: 0 1.40rem 0 1.40rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;

  .leftContent {
    width: auto;
    height: 92px;
    display: flex;
    align-items: center;

    .logo {
      display: block;
      width: 94px;
      height: 92px;
    }

    .title {
      width: auto;
      font-size: 30px;
      font-family: Roboto-BlackItalic, Roboto;
      font-weight: normal;
      color: #FFFFFF;
    }
  }

  .rightContent {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    li {
      width: auto;
      height: 24px;
      font-size: 20px;
      font-family: Roboto-BlackItalic, Roboto;
      font-weight: normal;
      color: #F9F9F9;
      line-height: 24px;
      margin-right: 34px;

      a {
        color: #F9F9F9;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .active {
      color: #17B449;
    }

    .normal {
      color: #fff;
    }
  }
}
