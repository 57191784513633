.homePage {
  min-width: 1366px;
  min-height: 100vh;
  background: #000;
  overflow-x: auto;

  .pageTitle {
    min-width: 1366px;
    height: 57px;
    font-size: 30px;
    font-family: Roboto-BlackItalic, Roboto;
    font-weight: normal;
    color: #17B449;
    line-height: 57px;
    text-align: center;
    margin-top: 30px;
  }

  .geometry {
    display: block;
    width: 1242px;
    height: 583px;
    margin: -120px auto 0;
    z-index: 1;
    object-fit: contain;
  }

  .centerGit {
    min-width: 1366px;
    height: 737px;
    display: flex;
    justify-content: center;
    margin: -223px auto 0;

    .infoname {
      width: 100%;
      font-size: 14px;
      font-family: Roboto-BlackItalic, Roboto;
      font-weight: normal;
      color: #F9F9F9;
      line-height: 16px;
      margin-top: 10px;
      padding-left: 45px;
    }

    .leftCon {
      width: 169px;
      padding-top: 294px;
      flex-shrink: 0;

      .oswap {
        display: block;
        width: 169px;
        height: 60px;
      }
    }

    .engineGroup {
      width: 737px;
      height: 737px;
      position: relative;
      flex-shrink: 0;

      .enginegif {
        display: block;
        width: 100%;
        height: 100%;
      }

      .Omnichain {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 737px;
        text-align: center;
        line-height: 737px;
        font-size: 35px;
        font-family: Roboto-BlackItalic, Roboto;
        font-weight: normal;
        color: #FFFFFF;
      }
    }


    .rightCon {
      width: 207px;
      padding-top: 294px;
      flex-shrink: 0;

      .oengine {
        display: block;
        width: 207px;
        height: 60px;
      }

      .rightInfo {
        padding-left: 45px;
      }
    }
  }

  .web3Title {
    min-width: 1366px;
    height: 47px;
    font-size: 40px;
    font-family: Roboto-BlackItalic, Roboto;
    font-weight: normal;
    color: #17B449;
    line-height: 47px;
    text-align: center;
    margin-top: -5px;
  }

  .numbergif {
    display: block;
    width: 550px;
    height: 350px;
    margin: 10px auto;
  }

  .imgGroup {
    width: 1366px;
    height: auto;
    margin: 0 auto;
    padding: 0 20px;

    .swapConents {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .leftimg {
        display: block;
        width: 544px;
        height: 366px;
      }

      .rightGroup {
        width: 711px;
        height: auto;
        padding-top: 107px;

        .nftLogo {
          display: block;
          width: 711px;
          height: 76px;
        }

        .addApp {
          margin-top: 94px;
        }
      }
    }

    .line {
      width: 100%;
      height: 1px;
      background: rgba(151, 151, 151, .2);
      margin: 77px 0 67px;
    }

    .DexGroup {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;

      .dexpng {
        display: block;
        width: 750px;
        // height: 390px;
        object-fit: contain;
      }

      .dexRight {
        flex: 1;
        text-align: right;

        .dexTitle {
          width: 100%;
          height: 53px;
          font-size: 30px;
          font-family: Roboto-BlackItalic, Roboto;
          font-weight: normal;
          color: #17B449;
          line-height: 53px;
        }

        .desc {
          width: auto;
          height: 24px;
          font-size: 20px;
          font-family: Roboto-BlackItalic, Roboto;
          font-weight: normal;
          color: #FFFFFF;
          line-height: 24px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 26px;

          .iconImg {
            display: block;
            width: 20px;
            height: 20px;
            margin-right: 4px;
          }
        }

        .adddex {
          margin-top: 72px;
        }
      }
    }
  }

  .activeTree {
    width: 100%;
    height: auto;
    margin-top: 96px;

    .treeTitle {
      width: 100%;
      height: 47px;
      font-size: 40px;
      font-family: Roboto-BlackItalic, Roboto;
      font-weight: normal;
      color: #17B449;
      line-height: 47px;
      text-align: center;
      margin-bottom: 70px;
    }

    .treeGroup {
      width: 414px;
      height: 436px;
      position: relative;
      margin: 0 auto;

      .treegif {
        display: block;
        width: 414px;
        height: 414px;
      }

      .tcom {
        width: auto;
        height: 35px;
        font-size: 30px;
        font-family: Roboto-BlackItalic, Roboto;
        font-weight: normal;
        color: #54FFFD;
        line-height: 35px;
        position: absolute;
      }

      .Gamefi {
        top: 77px;
        left: -57px;
      }

      .NFT {
        top: 33px;
        left: 108px;
      }

      .DEX {
        top: 33px;
        left: 252px;
      }

      .defi {
        top: 78px;
        right: -11px;
      }

      .crossChain {
        top: 211px;
        right: -62px;
      }

      .wallet {
        top: 212px;
        left: 14px;
      }
    }

    .ripple {
      display: block;
      width: 591px;
      height: 439px;
      margin: -292px auto 0;
    }

    .wave {
      width: 100%;
      height: auto;
      object-fit: contain;
      // margin-top:-100px;
      position: relative;
      top: -130px;
    }
  }

  .partners {
    margin: -80px auto 0;
    width: 400px;
    height: 400px;
    position: relative;

    .partnerGif {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
    }

    .pTitle {
      width: 100%;
      height: 400px;
      font-size: 40px;
      font-family: Roboto-BlackItalic, Roboto;
      font-weight: normal;
      color: #17B449;
      line-height: 400px;
      padding-left: 143px;
    }
  }

  .iconGroup {
    display: block;
    width: 911px;
    height: 156px;
    margin: 0 auto;
  }

  .infoGroup {
    width: 1334px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 162px auto 0;

    li {
      width: 400px;
      // height: 130px;
      background: #141414;
      border-radius: 8px;
      padding: 0 10px 10px;

      .htitle {
        width: 100%;
        height: 42px;
        line-height: 42px;
        font-size: 14px;
        font-family: Roboto-BlackItalic, Roboto;
        font-weight: normal;
        color: #17B449;
        text-align: center;
        border-bottom: 1px solid rgba(151, 151, 151, .2);
        margin-bottom: 10px;
      }

      .desc {
        width: 111%;
        height: auto;
        font-size: 12px;
        font-family: Roboto-MediumItalic, Roboto;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 14px;
        transform: scale(.90);
        transform-origin: left;
        // margin-top: 10px;
      }

      .addmore {
        width: 100%;
        height: 9px;
        font-size: 8px;
        font-family: Roboto-BlackItalic, Roboto;
        font-weight: normal;
        color: #17B449;
        line-height: 9px;
        margin-top: 14px;
        text-align: right;
      }
    }

    .second {
      position: relative;
      top: -42px;
    }
  }

  .fr {
    float: right;
  }

  .launchButton {
    width: 150px;
    height: 50px;
    font-size: 20px;
    font-family: Roboto-BlackItalic, Roboto;
    font-weight: normal;
    color: #FFFFFF;
    line-height: 50px;
    background: #aaa;
    text-align: center;
  }

  .buttonDesc {
    width: 150px;
    height: 16px;
    font-size: 14px;
    font-family: Roboto-BlackItalic, Roboto;
    font-weight: normal;
    color: #CBC5C5;
    line-height: 16px;
    text-align: center;
    margin-top: 3px;
  }
}
