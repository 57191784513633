.footerCom {
  min-width: 1366px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 140px 0;
  position: relative;
  background: #000;
  border-top: 1px solid rgba(151, 151, 151, .2);

  .footerLeft {
    height: 111px;
    display: flex;
    align-items: center;

    .footerLogo {
      display: block;
      width: 114px;
      height: 111px;
      margin-right: 2px;
    }

    .dLab {
      font-size: 20px;
      font-family: Roboto-BlackItalic, Roboto;
      font-weight: normal;
      color: #FFFFFF;
      line-height: 24px;
    }
  }

  .footRight {
    height: 111px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      display: block;
      width: 30px;
      height: 26px;
      margin-right: 80px;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .lastimg {
      height: 20px;
    }

  }

  .footerRImg {
    display: block;
    width: 142px;
    height: 142px;
    position: absolute;
    right: 26px;
    top: -105px;
  }
}
