.boxerPage {
  // position: relative;
  width: 100%;
  background: #000;
}

// .main-containter {
//   margin: auto 0;
//   height: 8rem;
//   width: 90%;
//   background-color: rgba(56, 56, 56, 0.5);
// }


// .top-image {
//   width: 100%;
//   height: 1.8rem;
//   position: absolute;
// }
.header-container {
  display: flex;
  justify-content: center;
  /* height: 1.5rem; */
}

.header-wrapper {
  width: 15rem;
  margin: 0 auto;
  position: relative;

  .title-desc {
    width: 100%;
    text-align: center;
    // height: 57px;
    font-size: .48rem;
    font-family: Roboto-BlackItalic, Roboto;
    font-weight: normal;
    color: #17B449;
    line-height: .57rem;
    margin: .30rem 0;
  }

  .right-header {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -.2rem;
    display: flex;
    align-items: center;
  }
}


.box-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.82rem;
  margin-bottom: 22px;
}

.box-wrapper {
  width: 70%;
  display: flex;
  justify-content: space-between;
}


.box-image-item {
  width: 2.5rem;
  height: 2.5rem;
}

.main-wrapper {
  width: 15rem;
  margin: 0 auto;

}

.distribution-boxer {
  width: 15rem;
  margin: .3rem auto 0;

  .distribution-title {
    width: 100%;
    font-size: .30rem;
    font-family: Roboto-BlackItalic, Roboto;
    font-weight: normal;
    color: #17B449;
    line-height: .35rem;
    margin-bottom: .38rem;
  }

  .distribution-content {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .distributionBoxerImg {
      width: 8rem;
      height: 3rem;
    }

    .distribution-info {
      flex: 1;
      margin-left: .35rem;

      .desc {
        width: 100%;
        height: auto;
        font-size: .18rem;
        font-family: Roboto-BlackItalic, Roboto;
        font-weight: normal;
        color: #FFFFFF;
        line-height: .21rem;

      }

      .mt27 {
        margin-bottom: .27rem;
      }

      .read-more {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: .2rem;

        .desc {
          margin-bottom: 0;
        }

        .more-button {
          width: 100px;
          height: 40px;
          background: #17B449;
          border-radius: 8px;
          font-size: 15px;
          font-family: Roboto-BlackItalic, Roboto;
          font-weight: normal;
          color: #FFFFFF;
          line-height: 40px;
          text-align: center;
        }
      }
    }
  }
}

.price-button {
  border-radius: 5px;
  width: 1.3rem;
  height: 0.60rem;
  background: #3E5BF2;
  font-size: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-family: RobotoSerifNormalRoman-Bold, RobotoSerifNormalRoman;
  font-weight: 'bold';
}

.price-desc {
  font-size: 0.14rem;
  color: #FFF;
  display: flex;
  justify-content: center;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-width: 0px !important;
}

.css-a52wz1-MuiSvgIcon-root-MuiSelect-icon {
  color: #fff !important;
}

.title {
  -webkit-mask: linear-gradient(to top, transparent, rgb(0, 0, 0));
}
